import React from 'react';
import styled from 'styled-components';
import { Description, H1, H3, SecondaryP } from '@/components/Typography';
import viewport1Img from '../../assets/img/salesHubVp1.png';
import viewport2Img from '../../assets/img/toolkitHomeVp5.png';
import FirstViewport from '@/components/FirstViewport';
import TickedItems from '@/components/TickedItems';
import ButtonDefault from '@/components/ButtonDefault';
import CarouselCard from '@/components/CarouselCard';
import Section from '@/components/Section';
import Banner from '@/components/Banner';
import bannerBg from '../../assets/img/b2bBannerBg.png';
import { SalesHubPageContent } from '@/pages/sales-activities';

const FirstVpWrapper = styled.div`
  background: linear-gradient(118.47deg, #f8f9fa 3.57%, #e4e6f0 100%);
`;

const SecondViewport = styled(FirstViewport)``;

const ThirdViewport = styled(Section)``;

const FourthViewport = styled(Section)`
  background-color: #fff;
`;

const CardWrapper = styled.div`
  display: flex;
  margin-top: 3rem;
  flex-wrap: wrap;
  @media (max-width: 1200px) {
    flex-basis: 100%;
    flex-wrap: wrap;
  }
`;

const CenteredH3 = styled(H3)`
  text-align: center;
`;

const WhiteBgViewportWrapper = styled.div`
  background: #fff;
`;

const StyledDescription = styled(Description)`
  font-weight: 500;
`;

const StyledButtonDefault = styled(ButtonDefault)`
  margin-top: 2rem;
`;

const StyledSecondaryP = styled(SecondaryP)`
  margin-bottom: 1rem;
`;

const DescriptionWrapper = styled.div`
  margin: 1rem 0;
`;

interface SalesHubProps {
  content: SalesHubPageContent;
}

const SalesHubContainer: React.FunctionComponent<SalesHubProps> = ({
  content: { viewport1, viewport2, viewport3, viewport4 },
}) => (
  <>
    <FirstVpWrapper>
      <FirstViewport img={viewport1Img}>
        <H1>
          <span className="accent-text"> {viewport1.title1}</span>
          {` `}
          {viewport1.title2}
          {` `}
        </H1>
        <StyledDescription>{viewport1.description}</StyledDescription>
        <TickedItems items={viewport1.tickedDescription} />
        <StyledButtonDefault to={viewport1.button.url}>
          {viewport1.button.label}
        </StyledButtonDefault>
      </FirstViewport>
    </FirstVpWrapper>

    <WhiteBgViewportWrapper>
      <SecondViewport img={viewport2Img} withRightPadding imgWidth="50%">
        <StyledSecondaryP className="accent-text-gray">
          {viewport2.label}
        </StyledSecondaryP>
        <H1>{viewport2.title}</H1>
        <DescriptionWrapper>
          <TickedItems items={viewport2.descriptionItems} />
        </DescriptionWrapper>
      </SecondViewport>
    </WhiteBgViewportWrapper>

    <ThirdViewport>
      <CenteredH3>{viewport3.title}</CenteredH3>
      <CardWrapper>
        {viewport3.benefitCards.map((item) => (
          <CarouselCard
            key={item.title}
            flexBasis="25%"
            marginBottom="2rem"
            title={item.title}
            text={item.description}
            cardImgSrc={item.imageSrc}
          />
        ))}
      </CardWrapper>
    </ThirdViewport>

    <FourthViewport>
      <Banner
        bgSrc={bannerBg}
        label={viewport4.bannerLabel}
        description={viewport4.bannerDescription}
        buttonLabel={viewport4.button.label}
        buttonUrl={viewport4.button.url}
      />
    </FourthViewport>
  </>
);

export default SalesHubContainer;
