import React from 'react';
import Main from '../containers/Layout';
import salesHubContent from '../../content/pages/sales-activities.yml';
import SalesHubContainer from '@/containers/toolkit/SalesHubContainer';

export interface SalesHubPageContent {
  viewport1: {
    title1: string;
    title2: string;
    description: string;
    tickedDescription: { item: string }[];
    button: {
      label: string;
      url: string;
    };
  };
  viewport2: {
    label: string;
    title: string;
    descriptionItems: {
      item: string;
    }[];
  };
  viewport3: {
    title: string;
    benefitCards: {
      title: string;
      description: string;
      imageSrc: string;
    }[];
  };
  viewport4: {
    bannerLabel: string;
    bannerDescription: string;
    button: { label: string; url: string };
  };
}

const SalesHub: React.FunctionComponent = () => {
  const { viewport1, viewport2, viewport3, viewport4 } =
    salesHubContent as unknown as SalesHubPageContent;
  return (
    <Main>
      <SalesHubContainer
        content={{
          viewport1,
          viewport2,
          viewport3,
          viewport4,
        }}
      />
    </Main>
  );
};

export default SalesHub;
